const mode = 'live';

// const dev = {

//     //API
//     apiBaseRoot: 'http://localhost:8888/v1',
//     apiTimeout: 100000,


//     //bank
//     bankName: 'Monzo',
//     BankHolder: 'LEWIS HARRY NASH',
//     bankNumber: 20989354,
//     bankSort: '04-00-03'
// }

// const live = {

//     //API
//     apiBaseRoot: 'https://bb-api.yaboilulu.me/v1',
//     apiTimeout: 100000,


//     //bank
//     bankName: 'Monzo',
//     BankHolder: 'LEWIS HARRY NASH',
//     bankNumber: 20989354,
//     bankSort: '04-00-03'
// }


export default {
    //API
    apiBaseRoot: 'https://bb-api.yaboilulu.me/v1',
    apiTimeout: 100000,


    //bank
    bankName: 'Monzo',
    BankHolder: 'LEWIS HARRY NASH',
    bankNumber: 20989354,
    bankSort: '04-00-03'
}